<template>
  <div id="logistics">
    <div class="logistics-number">共拆分成
        <span>{{packagenumber}}</span>
        个包裹发出</div>
    <div class="logistics-title" v-for="(item) in packagelist" :key="item.id">
      <div>
        <div v-if="logistics" class="logistics-center">
          <p>物流单号 785233665553333</p>
          <p>物流公司 顺丰物流</p>
        </div>
        <div v-else class="logistics-center-null">
          <p>未填写物流信息</p>
        </div>
        <p class="logistics-shop">共计发货
            <span>{{item.alreadyNumber}}</span>
            件商品</p>
      </div>
      <div>
        <van-icon :name="forward_icon_Inno" @click="() => moreshop(item)" />
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import forward_icon_Inno from "../../assets/icon/orderstatus/forward_icon_Inno@2x.png";
import { getOrderBusinessDeliverDetail } from "./service";
export default {
  data() {
    return {
      forward_icon_Inno,
      logistics: "",
      packagenumber:'',
      packagelist:[]
    };
  },
  mounted() {
    this.logisticslist();
  },
  methods: {
    logisticslist() {
      getOrderBusinessDeliverDetail(this.$route.query.orderNo).then((res) => {
        if (res.status === "0") {
          this.packagelist = res.data
          this.packagenumber = res.data.length
        }else{
            Toast(res.msg)
        }
      }).catch((err)=>{
          return err
      });
    },
    moreshop(item) {
      this.$router.push({ path: "/logisticsinformation" ,query:{
          orderNo:item.lineList[0].orderNo,
          resultId:item.resultId
      }});
    },
  },
};
</script>
<style lang="less" scoped>
#logistics {
  width: 100%;
  height: 100%;
  padding: 0 8px;
  .logistics-number {
    margin-top: 16px;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: rgb(153, 153, 153);
    text-align: left;
    line-height: 24px;
  }
  .logistics-title {
    margin-top: 16px;
    border: 1px solid rgb(170, 170, 170);
    padding: 0 16px;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    .logistics-center {
      font-size: 13px;
      color: #666666;
    }
    .logistics-center-null {
      font-size: 13px;
      color: #666666;
    }
    .logistics-shop {
      font-size: 13px;
      color: #999999;
    }
  }
}
</style>