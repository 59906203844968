/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";

const getOrderBusinessDeliverDetail = async (params) =>
  request({
    url: `/orderBusiness/getOrderBusinessDeliverDetail?isDetail=true&qp-orderNo-eq=${params}`,
    method: "get",
  });

 
export { getOrderBusinessDeliverDetail};
